import Vue from "vue";
import VueRouter from "vue-router";

import HomeView from "../views/HomeView.vue";
import ModelDetail from "../views/ModelDetail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/terms",
    name: "terms",
    meta: {
      title: "Terms Of Use",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/TermsView.vue"),
  },
  {
    path: "/policies",
    name: "policies",
    meta: {
      title: "General Policies",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/PoliciesView.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    meta: {
      title: "Privacy Policy",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/PrivacyView.vue"),
  },
  {
    path: "/rental-agreement",
    name: "rentalAgreement",
    meta: {
      title: "Rental Agreement",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/RentalAgreement.vue"),
  },
  {
    path: "/resources",
    name: "resources",
    meta: {
      title: "Resources",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "faqs" */ "../views/ResourcesView.vue"),
  },
  {
    path: "/tutorials",
    name: "tutorials",
    meta: {
      title: "Resources",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "faqs" */ "../views/TutorialsView.vue"),
  },
  {
    path: "/faqs",
    name: "faq",
    meta: {
      title: "Frequently Asked Questions",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "faqs" */ "../views/FaqsView.vue"),
  },
  {
    path: "/digital-key",
    name: "digitalKey",
    meta: {
      title: "Digital Key",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "faqs" */ "../views/DigitalKey.vue"),
  },
  {
    path: "/waiver",
    name: "waiver",
    meta: {
      title: "Collision Protection",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "waiver" */ "../views/CollisionProtection.vue"
      ),
  },
  {
    path: "/disclaimers",
    name: "disclaimers",
    meta: {
      title: "Legal Disclaimers",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "disclaimers" */ "../views/DisclaimersView.vue"
      ),
  },
  {
    path: "/sales",
    name: "sales",
    meta: {
      title: "Vehicles For Sale",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sales" */ "../views/UsedCarsView.vue"),
  },
  {
    path: "/careers/:id/apply",
    name: "jobApply",
    meta: {
      title: "Job Application",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "job" */ "../views/JobApplicationView.vue"),
  },
  {
    path: "/careers/:id/confirmed",
    name: "jobApplyConfirmed",
    meta: {
      title: "Job Application Confirmed",
    },
    beforeEnter: (to, from, next) => {
      const hasAccess = localStorage.getItem("accessJobConfirmed");
      if (hasAccess === "true") {
        next();
      } else {
        next("/careers");
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "job" */ "../views/JobApplicationConfirmedView.vue"
      ),
  },
  {
    path: "/careers/:id",
    name: "job",
    meta: {
      title: "Job",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "job" */ "../views/JobView.vue"),
  },
  {
    path: "/careers",
    name: "careers",
    meta: {
      title: "Careers",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "careers" */ "../views/CareersView.vue"),
  },
  {
    path: "/vehicle/:name",
    meta: {
      title: "Specs & Features",
    },
    name: "ModelDetail",
    component: ModelDetail,
    // beforeEnter: (to, next) => {
    //   document.title = `${to.params.name} - Model Detail`;
    //   next();
    // },
  },
  {
    path: "/vehicle/model/3/long-range",
    name: "Model3LRView",
    meta: {
      title: "Model 3 Long Range",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "model3Lr" */ "../views/models/Model3LRView.vue"
      ),
  },
  {
    path: "/vehicle/model/y/long-range",
    name: "ModelYLRView",
    meta: {
      title: "Model Y Long Range",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "modelYLr" */ "../views/models/ModelYLRView.vue"
      ),
  },
  {
    path: "/vehicle/model/3",
    name: "model3",
    meta: {
      title: "Model 3",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "model3" */ "../views/models/Model3View.vue"),
  },
  {
    path: "/vehicle/model/y",
    name: "modelY",
    meta: {
      title: "Model Y",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "modelY" */ "../views/models/ModelYView.vue"),
  },
  {
    path: "/vehicle/model/x",
    name: "modelX",
    meta: {
      title: "Model X",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "modelX" */ "../views/models/ModelXView.vue"),
  },
  {
    path: "/vehicle/model/s/plaid",
    name: "modelSPlaid",
    meta: {
      title: "Model S Plaid",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "modelSPlaid" */ "../views/models/ModelSPlaidView.vue"
      ),
  },
  {
    path: "/vehicle/model/s",
    name: "modelS",
    meta: {
      title: "Model S",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "modelS" */ "../views/models/ModelSView.vue"),
  },

  {
    path: "/contact",
    name: "contact",
    meta: {
      title: "Contact Us",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/blog",
    name: "blog",
    meta: {
      title: "Blog",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "blog" */ "../views/BlogView.vue"),
  },
  {
    path: "/blog/:slug",
    name: "article",
    meta: {
      title: "",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "article" */ "../views/ArticleView.vue"),
  },
  {
    path: "/error",
    name: "500",
    meta: {
      title: "Internal Error",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/InternalErrorView.vue"),
  },
  {
    path: "*",
    name: "404",
    meta: {
      title: "Page Not Found",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "pagenotfound" */ "../views/PageNotFoundView.vue"
      ),
  },
];

if (process.env.NODE_ENV === "development") {
  routes.push({
    path: "/story",
    name: "Story",
    meta: {
      title: "Base Component",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ComponentStory.vue"),
  });
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title + " | Resla";
  });
});

export default router;
