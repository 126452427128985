const vehicleService = (api) => ({
  getVehicleModels: async () => {
    return api.request({
      method: "get",
      url: "/api/v1/vehicle_models",
    });
  },
  getVehicle: async (vehicleVin) => {
    return api.request({
      method: "get",
      url: `/api/v1/vehicles/${vehicleVin}`,
    });
  },
  searchVehicles: async ({
    searchQueryId,
    model,
    variant,
    color,
    pickupDate,
    returnDate,
    pickupTime,
    returnTime
  }) => {
    return api.request({
      method: 'get',
      url: `/api/v1/vehicles/search/${searchQueryId}`,
      params: {
        model,
        variant,
        color,
        pickupDate,
        returnDate,
        pickupTime,
        returnTime
      }
    });
  }
});

export default vehicleService;
