<template>
    <div class="flex flex-col items-center justify-center w-full mt-16 lg:mt-24">
        <div class="flex flex-col justify-self-center items-center justify-center gap-6 max-w-lg">
            <div class="font-medium text-heading-1 leading-10 text-resla-ebony-20 tracking-negative-3 text-center">
                Models</div>
            <div class="text-resla-ebony-40 text-xl leading-8 font-normal text-center font-urbanist">With its advanced
                crash avoidance
                technology and high-strength reinforcement structure, you can feel confident and secure behind the
                wheel.</div>
        </div>
        <div class="mt-10 lg:mt-20 flex flex-col lg:flex-row gap-6 w-full">
            <template v-if="filteredModels.length > 0">
                <ModelCard v-for="model in filteredModels" :key="model.name" :model-detail="model"
                    :url="constructUrl(model)"></ModelCard>
            </template>
            <template v-else>
                <SkeletonLoader width="100%" height="670px" rounded></SkeletonLoader>
                <SkeletonLoader width="100%" height="670px" rounded></SkeletonLoader>
            </template>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

import ModelCard from '@/components/base/ModelCard.vue'
import SkeletonLoader from '../base/SkeletonLoader.vue';
export default {
    name: "ModelsSection",
    components: {
        ModelCard,
        SkeletonLoader
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapState(["vehicles", "filters"]),
        ...mapGetters(["pickupDateString", "returnDateString"]),

        filteredModels() {
            if (!this.vehicles || this.vehicles.length === 0) {
                return [
                    {
                        name: "Model 3",
                        colors: ["White", 'Grey', 'Black', 'Blue', 'Red'],
                        rent: 69,
                        model: "Model 3",
                        year: 2024,
                        public_image_link: "https://public-resla-assets.s3.amazonaws.com/Model+3.jpg",
                        drivetrain: "RWD",
                        mile_range: 363,
                        zero_to_sixty_mph_seconds: 4.9,
                        top_speed_mph: 125,
                        seating_capacity: 5
                    },
                    {
                        name: "Model Y",
                        colors: ["White", 'Grey', 'Black', 'Blue', 'Red'],
                        rent: 79,
                        model: "Model Y",
                        year: 2024,
                        public_image_link: "https://public-resla-assets.s3.us-east-1.amazonaws.com/app-vehicle-images/2023/Model_Y_WHITE_MONEYSHOT.jpg",
                        drivetrain: "RWD",
                        mile_range: 337,
                        zero_to_sixty_mph_seconds: 6.5,
                        top_speed_mph: 135,
                        seating_capacity: 5
                    }
                ];
            }

            const modelGroups = {};

            this.vehicles.forEach(vehicle => {
                const modelName = vehicle.model.name;

                if (!modelGroups[modelName]) {
                    modelGroups[modelName] = {
                        name: modelName,
                        colors: new Set(),
                        rack_rates: [],
                        model: vehicle.model.name,
                        year: vehicle.model.year,
                        public_image_link: vehicle.model.public_image_link,
                        drivetrain: vehicle.model.drivetrain,
                        mile_range: vehicle.model.mile_range,
                        zero_to_sixty_mph_seconds: vehicle.model.zero_to_sixty_mph_seconds,
                        top_speed_mph: vehicle.model.top_speed_mph,
                        seating_capacity: vehicle.model.seating_capacity
                    };
                }
                modelGroups[modelName].colors.add(vehicle.color);
                modelGroups[modelName].rack_rates.push(vehicle.rack_rate);
            });

            return Object.values(modelGroups)
                .filter(group => group.name === 'Model 3' || group.name === 'Model Y')
                .map(group => ({
                    name: group.name,
                    colors: Array.from(group.colors),
                    rent: Math.min(...group.rack_rates) / 100,
                    model: group.model,
                    year: group.year,
                    public_image_link: group.public_image_link,
                    drivetrain: group.drivetrain,
                    mile_range: group.mile_range,
                    zero_to_sixty_mph_seconds: group.zero_to_sixty_mph_seconds,
                    top_speed_mph: group.top_speed_mph,
                    seating_capacity: group.seating_capacity
                }));
        }
    },
    methods: {
        constructUrl(model) {
            return `${process.env.VUE_APP_RESLA_APP_ROOT_URL}/search?searchQuery=${this.filters.location.id}&pickupDate=${this.pickupDateString}&returnDate=${this.returnDateString}&model=${model.name}`
        }
    }
};
</script>