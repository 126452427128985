<template>
    <div class="flex flex-col gap-10 items-center justify-center w-full mt-20 mb-20">
        <!--Heading-->
        <div class="flex gap-6 items-center w-full justify-between">
            <div class="font-medium text-heading-1 leading-10 text-resla-ebony-1000 tracking-negative-3">
                Resources</div>
            <div class="hidden lg:block border-l border-resla-ebony-80 pl-6">
                <CtaButton variant="tertiary" trailing-icon to="/resources">
                    More
                </CtaButton>
            </div>

        </div>
        <!--content-->
        <div class="flex w-full flex-col lg:flex-row  gap-6">
            <div class="flex flex-col gap-4 w-full">
                <TutorialCard :title="video.title" :videoUrl="video.src" :tags="video.tags" :thumbnail="video.imgURL" />
            </div>
            <div class="flex flex-col gap-6 w-full">
                <!--Blog card - can be a seprate component -->
                <div class="order-last lg:!order-first flex flex-col lg:flex-row w-full">
                    <img src="@/assets/img/blog-thumbnail.png" alt="blog thumbnail" class="max-h-52 rounded-xl">
                    <div class="flex flex-col justify-between py-4 px-0 lg:!px-10 h-full">
                        <div class="flex flex-col gap-1">
                            <p class="font-urbanist font-normal text-base leading-6 text-relsa-ebony-1000">Wed May 22
                                2024</p>
                            <p class="font-medium text-2xl leading-9 text-relsa-ebony-1000">Experience the Tesla
                                Reengineered Model 3</p>
                        </div>
                        <CtaButton variant="tertiary" trailing-icon class="w-max" to="/blog/reengineered-model-3-tesla">
                            Read
                        </CtaButton>
                    </div>

                </div>
                <div class="border border-resla-ebony-70 rounded-xl overflow-hidden">
                    <FaqCard v-for="(faq, index) in faqs" :key="index" :question="faq.question" :answer="faq.answer"
                        :route="faq.route">
                    </FaqCard>
                </div>
            </div>
            <div class="block lg:hidden border-t border-resla-ebony-80 pt-6">
                <CtaButton variant="tertiary" trailing-icon to="/resources">
                    More
                </CtaButton>
            </div>
        </div>

    </div>
</template>
<script>
// import LinkIcon from '@/components/icons/LinkIcon.vue'
import CtaButton from '@/components/base/CtaButton.vue'
import FaqCard from '@/components/base/FaqCard.vue'
import TutorialCard from "@/components/base/TutorialCard";

export default {
    name: "ResourcesSection",
    components: {
        TutorialCard,
        CtaButton,
        FaqCard
    },
    data() {
        return {
            faqs: [
                {
                    question: 'How old do you have to be to rent a Resla?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    route: '/faqs#age-requirement',
                },
                {
                    question: 'What fees does Resla charge?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    route: '/faqs#resla-fees'
                },
                {
                    question: 'Is auto insurance required to rent with Resla?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    route: '/faqs#insurance-requirements'
                },
                {
                    question: 'Does the damage waiver cover damage to other vehicles?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    route: '/faqs#damage-waiver-coverage'
                },
                {
                    question: 'Do I need a physical key?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    route: '/faqs#digital-key'
                },
                {
                    question: 'Is there a limit on the number of miles I drive?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    route: '/faqs#mileage-limit'
                },
                {
                    question: 'What is the mileage range with each charge?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    route: '/faqs#mileage-range'
                }
            ],
            video: {
                src: "https://www.youtube.com/embed/QvhYeJsGQEA?si=QbA-19Zc1WZEpCL0",
                imgURL: '2024-model-3',
                title: "Updates to the 2024 Model 3",
                tags: [{name:"Rental Process", value:"rental_process"},{ name: "Driving", value: "driving" }
                ]
            }

        }
    }
};
</script>