<template>
  <component :is="url ? 'a' : (route ? 'router-link' : 'div')" :href="url ? url : undefined"
    :target="url ? '_blank' : ''" :to="route ? route : undefined"
    class="border w-full border-resla-ebony-70 rounded-xl bg-primary-900 hover:bg-primary-800 cursor-pointer overflow-hidden">
    <img :src="modelDetail.public_image_link" class="flex rounded-t-xl w-full max-h-[400px] h-full object-cover" />
    <div class="p-6 md:p-10 gap-6 w-full flex flex-col">
      <!-- Model Name & Rent -->
      <div class="flex justify-between items-center">
        <p class="font-medium text-[32px] leading-8 text-resla-ebony-20">
          {{ modelDetail.name }}
        </p>

        <div class="flex gap-6">
          <!-- Colors Section -->
          <div class="hidden md:flex items-center gap-3">
            <p class="font-normal text-resla-ebony-10 text-base leading-6">Available in</p>
            <div class="flex -space-x-1">
              <div v-for="(color, index) in colorGradients" :key="index" :class="color" class="w-5 h-5 rounded-full"
                aria-label="Car color option"></div>
            </div>
          </div>
          <div class="flex">
            <p class="text-heading-1 font-medium text-resla-ebony-20 leading-8">
              ${{ modelDetail.rent }}
            </p>
            <sub class="inline-block text-xl font-normal">/day</sub>
          </div>
        </div>
      </div>

      <!-- Mobile Colors Section -->
      <div class="flex w-full md:hidden items-center gap-3">
        <p class="font-normal text-resla-ebony-10 text-base leading-6">Available in</p>
        <div class="flex -space-x-1">
          <div v-for="(color, index) in colorGradients" :key="index" :class="color" class="w-5 h-5 rounded-full"
            aria-label="Car color option"></div>
        </div>
      </div>

      <div class="w-full border border-resla-ebony-70"></div>

      <!-- Model Details -->
      <div class="flex gap-8 items-center">
        <div class="w-full block md:flex gap-3 items-center">
          <div class="font-medium text-2xl leading-9 text-resla-ebony-10">
            {{ modelDetail.mile_range }}
          </div>
          <div class="font-normal text-base text-resla-ebony-10 font-urbanist">
            mi Range (EPA est.)
          </div>
        </div>
        <div class="w-full block md:flex gap-3 items-center justify-end">
          <div class="font-medium text-2xl leading-9 text-resla-ebony-10">
            0-60
          </div>
          <div class="font-normal text-base text-resla-ebony-10 font-urbanist">
            mph in {{ modelDetail.zero_to_sixty_mph_seconds }} sec
          </div>
        </div>
      </div>

      <div class="mt-3 flex gap-8 items-center">
        <div class="w-full block md:flex gap-3 items-center">
          <div class="inline-block font-medium text-2xl leading-9 text-resla-ebony-10">
            {{ modelDetail.top_speed_mph }}
          </div>
          <div class="font-normal text-base text-resla-ebony-10 font-urbanist">
            mph top speed
          </div>
        </div>
        <div class="w-full block md:flex gap-3 items-center justify-end">
          <div class="inline-block font-medium text-2xl leading-9 text-resla-ebony-10">
            Dual
          </div>
          <div class="font-normal text-base text-resla-ebony-10 font-urbanist">
            AWD
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
const availableColor = ['paint-red', 'paint-blue', 'paint-black', 'paint-pearl', 'paint-silver']
export default {
  name: "ModelCard",
  props: {
    modelDetail: {
      type: Object,
    },
    route: {
      type: String,
    },
    url: {
      type: String,
    }
  },
  computed: {
    colorGradients() {
      const colorMap = {
        'White': 'paint-pearl',
        'Grey': 'paint-silver',
        'Black': 'paint-black',
        'Blue': 'paint-blue',
        'Red': 'paint-red',
      };

      if (this.modelDetail.colors && this.modelDetail.colors.length > 0) {
        return this.modelDetail.colors.map(color => {
          return `bg-${colorMap[color]}`;
        });
      } else {
        return availableColor.map(color => `bg-${color}`);
      }
    },
  },
};
</script>