const searchService = (api) => ({
    getAutocompleteSuggestions: async (query, limit = 5) => {
        return api.request({
            method: 'get',
            url: '/api/v1/search/autocomplete',
            params: {
                query,
                limit
            }
        });
    },
    getCitySuggestions: async () => {
        return api.request({
            method: 'get',
            url: '/api/v1/search/cities'
        });
    },
});

export default searchService; 