<template>
  <div>
    <div v-if="modelDetail && modelDetail.name"
      class="model-detail-container flex border-x border-primary-700 flex-col gap-10 px-6 lg:px-10">
      <div :class="isSticky ? 'pb-5 md:pb-2' : ''"
        class="flex flex-col pt-5 md:pt-10 w-full justify-center items-center gap-6 sticky top-[125px] md:top-[40px] bg-primary-1000 z-10 flex-wrap">
        <!--Desktop nav-->
        <div class="hidden md:flex flex-row justify-between items-center w-full flex-wrap">
          <div class="font-medium tracking-negative-5 text-resla-ebony-1000 flex"
            :class="isSticky ? 'md:text-2xl leading-6 py-3' : 'text-heading'">
            {{ modelDetail.name }}
          </div>
          <div class="flex flex-col md:flex-row items-center gap-6">
            <div class="flex items-center gap-3">
              <p class="font-normal text-resla-ebony-10 text-base leading-6">Available in</p>
              <div class="flex -space-x-1">
                <div v-for="(color, index) in colorGradients" :key="index" :class="color" class="w-5 h-5 rounded-full"
                  aria-label="Car color option"></div>
              </div>
            </div>
            <div class="flex">
              <p class="text-heading-1 font-medium text-resla-ebony-20 leading-8">
                ${{ modelDetail.rent }}
              </p>
              <sub class="inline-block text-xl font-normal">/day</sub>
            </div>
            <CtaButton :size="isSticky ? 'small' : 'large'" class="w-full md:w-max" @click="handleBookNow">
              {{ isSticky ? 'Book' : 'Book Now' }}
            </CtaButton>
          </div>
        </div>
        <!--Mobile nav-->
        <div class="flex md:hidden flex-col gap-6 w-full">
          <div class="flex justify-between items-center w-full">
            <div :class="{ 'flex gap-5 items-center': isSticky }">
              <img v-if="isSticky" :src="modelDetail.public_image_link" class="size-16 rounded-lg object-cover" />
              <div class="font-medium tracking-negative-5 text-resla-ebony-1000 flex"
                :class="isSticky ? 'text-2xl leading-6 py-3' : 'text-heading'">
                {{ modelDetail.name }}
              </div>
              <div v-if="!isSticky" class="flex items-center gap-3">
                <p class="font-normal text-resla-ebony-10 text-base leading-6">Available in</p>
                <div class="flex -space-x-1">
                  <div v-for="(color, index) in colorGradients" :key="index" :class="color" class="w-5 h-5 rounded-full"
                    aria-label="Car color option"></div>
                </div>
              </div>
            </div>
            <CtaButton v-if="isSticky" size="small" @click="handleBookNow">
              Book
            </CtaButton>
            <div v-if="!isSticky" class="flex">
              <p class="text-heading-1 font-medium text-resla-ebony-20 leading-8">
                ${{ modelDetail.rent }}
              </p>
              <sub class="inline-block text-xl font-normal">/day</sub>
            </div>
          </div>
          <CtaButton v-if="!isSticky" size="large" class="w-full" @click="handleBookNow">
            Book Now
          </CtaButton>
        </div>
      </div>
      <!--public image-->
      <div class="flex  w-full justify-center items-center max-h-[632px] overflow-hidden rounded-lg md:rounded-xl">
        <img :src="modelDetail.public_image_link" />
      </div>
      <!--metrics-->
      <div class="grid grid-cols-3 gap-4 border-y border-primary-700">


        <div class="flex gap-3 items-center flex-col md:flex-row">
          <div class="text-heading-1 text-resla-ebony-10 font-medium">0-60</div>
          <div class="flex text-base md:text-xl leading-8 font-Urbanist text-resla-ebony-10 font-medium">
            mph in {{ modelDetail.zero_to_sixty_mph_seconds }} sec
          </div>
        </div>

        <div class="flex gap-3 items-center flex-col md:flex-row">
          <div class="flex text-heading-1 text-resla-ebony-10 font-medium">{{ modelDetail.mile_range }}</div>
          <div class="flex text-base md:text-xl leading-8 font-Urbanist text-resla-ebony-10 font-medium">Mile Range</div>
        </div>

        <div class="flex gap-3 items-center flex-col md:flex-row">
          <div class="flex text-heading-1 text-resla-ebony-10 font-medium">{{ modelDetail.drivetrain }}</div>
          <div class="flex text-base md:text-xl leading-8 font-Urbanist text-resla-ebony-10 font-medium">Drivetrain</div>
        </div>

        <!-- <div class="flex gap-3 items-center flex-col md:flex-row">
          <div class="text-heading-1 text-resla-ebony-10 font-medium">{{ modelDetail.top_speed_mph }}</div>
          <div class="text-xl leading-8 font-Urbanist text-resla-ebony-10 font-medium">mph top speed</div>
        </div> -->
      </div>
      <!--features-->
      <div class="flex gap-20 md:gap-10 flex-col w-full justify-center items-center">
        <div v-for="(feature, index) in modelDetail.features" :key="index"
          class="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-4">
          <img :src="feature.image" class="w-full rounded-lg md:min-h-[25.6rem]">
          <div class="flex flex-col gap-8 lg:px-20 justify-center items-center">
            <div class="flex flex-col">
              <h4 class="font-medium text-2xl leading-[38.4px] negative-2 text-resla-ebony-1000">{{ feature.title }}
              </h4>
              <p class="feature-desc font-urbanist text-xl leading-8 font-normal text-left text-primary-400"
                v-html="feature.description"></p>
            </div>
          </div>
        </div>
      </div>
      <LegalPageCta heading="Ready to get on the road?"></LegalPageCta>
    </div>
    <div v-else class="flex flex-col gap-10 px-6 lg:px-10 py-10">
      <SkeletonLoader width="100%" height="58px" rounded></SkeletonLoader>
      <SkeletonLoader width="100%" height="632px" rounded></SkeletonLoader>
      <div class="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-4">
        <SkeletonLoader width="100%" height="408px" rounded></SkeletonLoader>
        <div class="flex flex-col gap-8 px-5">
          <SkeletonLoader width="150px" height="18px" rounded radius="4px"></SkeletonLoader>
          <SkeletonLoader width="100%" height="16px" rounded radius="4px"></SkeletonLoader>
          <SkeletonLoader width="96%" height="16px" rounded radius="4px"></SkeletonLoader>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-4">
        <SkeletonLoader width="100%" height="408px" rounded></SkeletonLoader>
        <div class="flex flex-col gap-8 px-5">
          <SkeletonLoader width="150px" height="18px" rounded radius="4px"></SkeletonLoader>
          <SkeletonLoader width="100%" height="16px" rounded radius="4px"></SkeletonLoader>
          <SkeletonLoader width="96%" height="16px" rounded radius="4px"></SkeletonLoader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import CtaButton from "@/components/base/CtaButton";
import LegalPageCta from "@/components/LegalPageCta";
import SkeletonLoader from '@/components/base/SkeletonLoader.vue';

const availableColor = ['paint-red', 'paint-blue', 'paint-black', 'paint-pearl', 'paint-silver']
export default {
  name: "ModelDetails",
  components: {
    CtaButton,
    LegalPageCta,
    SkeletonLoader
  },
  data() {
    return {
      isSticky: false,
      modelName: 'Model 3'
    }
  },
  computed: {
    ...mapState(["models", "filters"]),
    ...mapGetters(['pickupDateString', 'returnDateString']),
    modelDetail() {
      const modelName = this.modelName?.replace(/-/g, ' ').trim().toLowerCase() || ''; // replace dashes with spaces
      return {
        ...this.models?.find(vehicle =>
          vehicle?.name?.trim().toLowerCase() === modelName
        ),
        // additional keys
        rent: '39',
        colors: ['paint-red', 'paint-pearl', 'paint-black', 'paint-blue'],
      };
    },
    colorGradients() {
      // color are added in tailwind theme
      return (this.modelDetail.colors && this.modelDetail.colors.length > 0)
        ? this.modelDetail.colors.map((color) => `bg-${color}`)
        : availableColor.map((color) => `bg-${color}`);
    }
  },
  watch: {
    modelDetail(newModelDetail) {
      if (newModelDetail && newModelDetail.name) {
        this.setPageTitle(newModelDetail.name);
      }
    }
  },
  methods: {
    handleScroll() {
      this.isSticky = window.scrollY > 60;
    },
    setPageTitle(modelName = '') {
      document.title = `${modelName} - Specs & Features | Resla`;
    },
    handleBookNow() {
      let model = this.modelDetail.name

      window.open(`${process.env.VUE_APP_RESLA_APP_ROOT_URL}/search?searchQuery=${this.filters.location.id}&pickupDate=${this.pickupDateString}&returnDate=${this.returnDateString}&model=${model}`, '_blank')
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.modelName = decodeURIComponent(this.$route.params.name)
    if (this.modelDetail && this.modelDetail.name) {
      this.setPageTitle(this.modelDetail.name);
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>

<style>
.feature-desc>a {
  text-decoration-line: underline;
}
</style>