import axios from "axios";
import locationService from "./location";
import careerService from "./careers";
import vehicleService from "./vehicle";
import agreementService from "./agreements";
import searchService from "./search";
class API {
  constructor(rootUrl) {
    this.rootUrl = rootUrl;
  }

  async request({ method, url, data = null, params = null }) {
    try {
      const response = await axios({
        method,
        url: `${this.rootUrl}${url}`,
        data,
        params,
      });
      return response.data;
    } catch (error) {
      console.error(`Error making ${method} request to ${url}:`, error);
      throw error;
    }
  }
}

const apiInstance = new API(process.env.VUE_APP_ROOT_URL);
const careerApiInstance = new API("https://boards-api.greenhouse.io/v1");

const api = {
  location: locationService(apiInstance),
  career: careerService(careerApiInstance), // Use the separate instance for careers
  vehicle: vehicleService(apiInstance),
  agreements: agreementService(apiInstance),
  search: searchService(apiInstance),
};

export default api;
