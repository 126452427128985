<template>
  <div class="flex items-center align-middle bg-resla-ebony-90 rounded-lg border border-resla-ebony-70 relative">
    <!--location dropdown-->
    <div class="flex-1 border-r border-resla-ebony-70 h-full flex items-center ">
      <AutoComplete class="!static w-full" compact :picker-class="'rounded-lg overflow-hidden top-14 lg:top-[70px]'"
        id="location" :value="filters.location" @input="handleLocation" :options="cityOptions" is-nav
        value-key="query" />
    </div>

    <!--date picker-->
    <div class="flex-1 border-r border-resla-ebony-70 h-full  flex items-center">
      <DatepickerModal :id="datePickerID" compact place-holder="Dates"
        :date-value="{ startDate: filters.pickupDate, endDate: filters.returnDate }"
        :time-value="{ startTime: filters.pickupTime, endTime: filters.returnTime }" @apply="handleBookingDateTime">

      </DatepickerModal>
    </div>

    <CtaButton variant="secondary" trailing-icon size="small" class="border-0 !p-2 !h-full !rounded-l-none"
      :is-disabled="!filters.location || !filters.pickupDate || !filters.returnDate" @click="handleNavSearch" />
  </div>
</template>


<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DatepickerModal from "./base/DatepickerModal.vue";
import AutoComplete from "@/components/AutoComplete.vue";
import CtaButton from "@/components/base/CtaButton.vue";
export default {
  name: "NavInputs",
  components: {
    DatepickerModal,
    AutoComplete,
    CtaButton,
  },
  props: {
    datePickerID: {
      type: String,
      required: true,
    },
    isScrolledPast: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["cities", "filters"]),
    ...mapGetters(['pickupDateString', 'returnDateString']),
    cityOptions() {
      return this.cities.map((city, index) => ({
        id: index,
        label: city.query,
        value: city,
      }));
    },
  },
  methods: {
    ...mapActions(["setLocation", "setPickupDate", "setReturnDate", "setPickupTime", "setReturnTime"]),
    handleLocation(value) {
      this.setLocation(value)
    },
    handleBookingDates({ startDate, endDate }) {
      this.setPickupDate(startDate)
      this.setReturnDate(endDate)
    },
    handleBookingTime({ startTime, endTime }) {
      this.setPickupTime(startTime)
      this.setReturnTime(endTime)
    },
    handleBookingDateTime({ dateValue, timeValue }) {
      this.handleBookingDates(dateValue)
      this.handleBookingTime(timeValue)
    },
    handleNavSearch() {
      if (this.filters.location && this.filters.pickupDate && this.filters.returnDate) {
        const url = `${process.env.VUE_APP_RESLA_APP_ROOT_URL}/search?searchQuery=${this.filters.location.id}&pickupDate=${this.pickupDateString}&returnDate=${this.returnDateString}`;
        window.open(url, '_blank')
      } else {
        console.log('Location or dates are missing.');
      }
    },
  },
};
</script>
