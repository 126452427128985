import mixpanel from 'mixpanel-browser';
import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import router from '@/router'

export function initialize() {
    // Initialize Mixpanel if token exists
    if (process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN) {
        mixpanel.init(process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN, {
            debug: false,
            track_pageview: true,
            persistence: 'localStorage'
        });
    }

    // Initialize GTM if ID exists
    if (process.env.VUE_APP_GTM_ID) {
        Vue.use(VueGtm, {
            id: process.env.VUE_APP_GTM_ID,
            enabled: true,
            debug: false,
            loadScript: true,
            vueRouter: router,
            defer: false,
            compatibility: false,
            trackOnNextTick: false
        });
    }
}