<template>
  <div
    class="flex flex-col gap-8 text-center items-center py-28 lg:px-0 px-10 border-t border-primary-700 bg-[linear-gradient(180deg,_#F5F5F7_37.39%,_#F2F1F0_100%)]">
    <h2 class="text-[56px] text-black font-medium tracking-negative-3">
      {{ heading }}
      <br v-if="subHeading"/>
      <span v-if="subHeading">{{ subHeading }}</span>
    </h2>
    <div class="flex gap-3 items-center">

      <CtaButton @click="handleBookNow">
        {{ btnLabel }}
      </CtaButton>

      <router-link to="/contact" class="text-xl  text-black font-medium tracking-negative-3">Contact Us</router-link>
      
    </div>
    
  </div>
</template>

<script>
import CtaButton from '@/components/base/CtaButton.vue';

export default {
  name: "LegalPageCta",
  components: {
    CtaButton
  },
  props: {
    heading: {
      type: String,
      default: 'Still have questions?'
    },
    subHeading: {
      type: String,
    },
    btnLabel:{
      type: String,
      default: 'Book Now'
    },
    to: {
      type: String,
    },
  },
  methods:{
    handleBookNow(){
            window.open(`${process.env.VUE_APP_RESLA_APP_ROOT_URL}/search`,'_blank');
        }
  }
};
</script>
